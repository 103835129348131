@tailwind base;
@tailwind components;
@tailwind utilities;


.fc-event{
    background-color: #d1fae5;
    border: none;
    border-radius: 15px;
    padding: 0.3rem 0.3rem 0.3rem 0.5rem;
    margin-top:0.6rem;
  }

  .fc-event-main div{
    color:green;
    font-weight: bold;
  }

  .fc-toolbar-title, .fc-timeline-slot-frame, .fc-datagrid-cell-main {
    color:#667280;
  }

  .fc-timeline-lane-frame {
    display: flex;
    align-items: center;
  }

  .fc-header-toolbar {
    background:#f3f4f6;
    padding: 1rem 0.5rem;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0rem;
  }

  .fc-timeline-header-row{
    background:#f3f4f6;
  }

  .fc-timeline-slot-frame {
    height: 50px !important;
  }

  .fc-datagrid-header{
    background: #f3f4f6;
  }
  .fc th {
    height: 50px !important;
  }

  .fc {
    max-height: calc(100vh - 65px);
  }

  .fc-event-time {
    color: #ca8a04;
  }

  .fc-theme-standard td, .fc-theme-standard th {
    height: 60px;
    vertical-align: middle;
  }
  .responsible p {
    color:rgb(104, 170, 104);
  }